
import axios from "@/plugins/axios.js"

const actions  = {
  addProduct({ commit }, product) {
    return new Promise((resolve, reject) => {
      axios.post("/products/create", product)
      .then((response) => {
          if(response.data.success){
            commit('ADD_PRODUCT', response.data.data.product)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchProducts({ commit, state }, force = false) {
    if(!force && state.products.length) return
    return new Promise((resolve, reject) => {
      axios.get("/products")
        .then((response) => {
          if(response.data.success){
            commit('SET_PRODUCTS', response.data.data.products)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchProduct({ commit }, productId) {
    return new Promise((resolve, reject) => {
      axios.get(`/products/${productId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_PRODUCT', response.data.data.product)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}


const getters = {
	totalProducts: (state) => state.products.length,
}

const mutations = {
  ADD_PRODUCT(state, product) {
    state.products.unshift(product)
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_PRODUCT(state, product) {
    state.product = {...product};
    if(state.products.length){
      const dataIndex = state.products.findIndex((o) => o.id == product.id);
      Object.assign(state.products[dataIndex], product);
    }
  },
  UPDATE_PRODUCT(state, product) {
    Object.assign(state.product, product);
    if(state.products.length){
      const dataIndex = state.products.findIndex((o) => o.id == product.id);
      Object.assign(state.products[dataIndex], product);
    }
  },
  DELETE_PRODUCT(state, productId) {
    const ItemIndex = state.products.findIndex((item) => item.id == productId)
    state.products.splice(ItemIndex, 1)
  },

  /************** FOR DESCRIPTION *****************/
  UPDATE_DESCRIPTION(state, description){
    state.product.description = description
  },

  /************** FOR PACKAGE *****************/
  SET_PACKAGES(state, packages){
    state.product.product_packages = [...packages]
  },

  /************** FOR ADDONS *****************/
  ADD_ADDON(state, addon){
    state.product.addons.unshift(addon);
  },
  SET_ADDONS(state, addons){
    state.product.addons = [...addons];
  },
  UPDATE_ADDON(state, addon){
    const dataIndex = state.product.addons.findIndex((i) => i.id == addon.id)
     Object.assign(state.product.addons[dataIndex], addon)
  },
  DELETE_ADDON(state, addonId){
    const ItemIndex = state.product.addons.findIndex((item) => item.id == addonId)
    state.product.addons.splice(ItemIndex, 1);
  },

  /**************** THIS IS FOR ATTRIBUTES *************************/
  ADD_ATTRIBUTE(state, attribute){
    state.product.attributes.unshift(attribute)
  },
  UPDATE_ATTRIBUTE(state, attribute){
    const dataIndex = state.product.attributes.findIndex((a) => a.id == attribute.id);
    Object.assign(state.product.attributes[dataIndex], attribute);
  },
  DELETE_ATTRIBUTE(state, attributeId){
    const itemIndex = state.product.attributes.findIndex((item) => item.id == attributeId)
      state.product.attributes.splice(itemIndex, 1)
  },

  /**************** THIS IS FOR INDUSTRIES *************************/
  ADD_INDUSTRY(state, item){
    state.product.industries.unshift(item)
  },
  UPDATE_INDUSTRY(state, item){
    const dataIndex = state.product.industries.findIndex((a) => a.id == item.id);
    Object.assign(state.product.industries[dataIndex], item);
  },
  DELETE_INDUSTRY(state, itemId){
    const itemIndex = state.product.industries.findIndex((item) => item.id == itemId)
    state.product.industries.splice(itemIndex, 1)
  },

  /************** FOR QUESTIONS *****************/
  ADD_QUESTION(state, question){
    state.product.questions.unshift(question);
  },
  SET_QUESTIONS(state, questions){
    state.product.questions = [...questions];
  },
  UPDATE_QUESTION(state, question){
    const dataIndex = state.product.questions.findIndex((i) => i.id == question.id)
     Object.assign(state.product.questions[dataIndex], question)
  },
  DELETE_QUESTION(state, questionId){
    const ItemIndex = state.product.questions.findIndex((item) => item.id == questionId)
    state.product.questions.splice(ItemIndex, 1);
  },

    /************** FOR REQUIREMENTS *****************/
    ADD_REQUIREMENT(state, requirement){
      state.product.requirements.unshift(requirement);
    },
    SET_REQUIREMENTS(state, requirements){
      state.product.requirements = [...requirements];
    },
    UPDATE_REQUIREMENT(state, requirement){
      const dataIndex = state.product.requirements.findIndex((i) => i.id == requirement.id)
       Object.assign(state.product.requirements[dataIndex], requirement)
    },
    DELETE_REQUIREMENT(state, requirementId){
      const ItemIndex = state.product.requirements.findIndex((item) => item.id == requirementId)
      state.product.requirements.splice(ItemIndex, 1);
    },

  /************** FOR PORTFOLIOS *****************/
  ADD_PORTFOLIO(state, portfolio){
    state.product.portfolios.unshift(portfolio);
  },
  SET_PORTFOLIOS(state, portfolios){
    state.product.portfolios = [...portfolios];
  },
  UPDATE_PORTFOLIO(state, portfolio){
    const dataIndex = state.product.portfolios.findIndex((i) => i.id == portfolio.id)
     Object.assign(state.product.portfolios[dataIndex], portfolio)
  },
  DELETE_PORTFOLIO(state, portfolioId){
    const ItemIndex = state.product.portfolios.findIndex((item) => item.id == portfolioId)
    state.product.portfolios.splice(ItemIndex, 1);
  },

  /************** FOR HOW IT WORKS *****************/
  ADD_HIWS(state, step){
    state.product.how_it_works.unshift(step);
  },
  UPDATE_HIWS(state, step){
    const dataIndex = state.product.how_it_works.findIndex((i) => i.id == step.id)
      Object.assign(state.product.how_it_works[dataIndex], step)
  },
  DELETE_HIWS(state, stepId){
    const ItemIndex = state.product.how_it_works.findIndex((item) => item.id == stepId)
    state.product.how_it_works.splice(ItemIndex, 1);
  },

  /************** FOR HOW IT WORKS *****************/
  ADD_PAGE_REVIEW(state, review){
    state.product.page_reviews.unshift(review);
  },
  UPDATE_PAGE_REVIEW(state, review){
    const dataIndex = state.product.page_reviews.findIndex((i) => i.id == review.id)
      Object.assign(state.product.page_reviews[dataIndex], review)
  },
  DELETE_PAGE_REVIEW(state, reviewId){
    const ItemIndex = state.product.page_reviews.findIndex((item) => item.id == reviewId)
    state.product.page_reviews.splice(ItemIndex, 1);
  },
}

const state = {
  products: [],
  product:{},
  inputTypes:{
    pro_input: "Simple Input",
    pro_select: "Select Input",
    pro_textarea: "Textarea",
    photo_gallery: "Photo Gallery",
    color_gallery: "Color Gallery",
    pro_checkbox: "Checkbox",
    pro_radio: "Radio Input",
    file_uploader: "File Uploader",
  }
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

